<template>
	<div>
		<el-card shadow="never" id="card" :body-style="{
      minHeight: 'calc(100vh - 153px)'
    }">
			<div class="title" v-if="localStorageType==1">
<!--				<img-->
<!--					:src="companyLogo"-->
<!--					alt=""-->
<!--				>-->
				<span>
          HI，开源医疗！
        </span>
			</div>

      <div class="title" v-if="localStorageType==2">
<!--        <img-->
<!--            :src="companyLogo"-->
<!--            alt=""-->
<!--        >-->
        <span>
          HI，开源企业！
        </span>
      </div>

			<el-collapse v-model="activeNames" :accordion="true">
				<el-collapse-item
					v-for="(item, idx) in accountInfo"
					:key="item.createDate"
					:name="idx"
				>
					<div slot="title" style="font-size: 18px;font-weight: 600;">
						{{ item.createDate }}
					</div>
					<div class="content">
						<div v-for="(i, index) in item.list" :key="index" class="row">
							<div style="cursor: pointer" @click="handleClick(i)" v-html="i.msg"></div>
							<el-divider v-if="index !== item.list.length - 1"/>
						</div>
					</div>
				</el-collapse-item>
			</el-collapse>
			<el-empty v-if="accountInfo.length === 0" />
			<el-pagination
				background
				style="margin-top: 16px;"
				layout="prev, pager, next"
				@current-change="handlePageChange"
				:hide-on-single-page="true"
				:page-size="pagination.pageSize"
				:current-page="pagination.pageNum"
				:total="total">
			</el-pagination>
		</el-card>
	</div>
</template>

<script>
import {Card, Collapse, CollapseItem, Divider, Empty, Loading, Pagination} from "element-ui"
import {getUserInfo, getWelcomePageInfo} from "@/api/accountCenter";

export default {
	components: {
		[Divider.name]: Divider,
		[Card.name]: Card,
		[Collapse.name]: Collapse,
		[CollapseItem.name]: CollapseItem,
		[Pagination.name]: Pagination,
		[Empty.name]: Empty,
	},
	data() {
		return {
			pagination: {
				pageSize: 10,
				pageNum: 1
			},
			total: 0,
			accountInfo: [],
			activeNames: 0,
			load: null,
      localStorageType: localStorage.getItem("type") || 2,
			companyLogo: null
		}
	},
	mounted() {
		this.getList();
	},
	methods: {
		handlePageChange(currentPage) {
			this.pagination.pageNum = currentPage
			this.getList()
		},
		getList() {
			this.load = Loading.service({
				target: document.getElementById('card'),
				text: '加载中...'
			})
			getWelcomePageInfo(this.pagination).then(async (res) => {
				if (!this.companyLogo) {
					const response = await getUserInfo()
					this.companyLogo = response.data.companyLogo || require('@/assets/kyyl-nt.png')
				}
				this.total = res.data.total
				this.accountInfo = res.data.list
				this.activeNames = 0
				this.load.close()
			}).catch(() => {
				this.load.close()
			})
		},
		handleClick(item) {
			console.log(item);
			if (item.typeId === 1 || item.typeId === 2) {
				this.$router.push({
					name: 'TrialApplication'
				})
			} else {
				this.$router.push({
					name: 'detail',
					params: {
						id: item.id
					}
				})
			}
		}
	}
}

</script>

<style lang="scss" scoped>
.title {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-bottom: 48px;
	margin-top: 16px;
	
	img {
		width: 80px;
		height: 80px;
		margin-right: 40px;
	}
	
	span {
		font-size: 24px;
		color: #2299F5;
		font-weight: 600;
	}
}

.content {
	margin: 16px auto 0;
	max-width: 800px;
	
	.row {
		//display: flex;
		//align-items: center;
		font-size: 16px;
		
		/deep/ .color-r {
			color: #F5313D;
		}
		
		/deep/ .color-b {
			color: #4850FF;
		}
		
		/deep/ .ml-4 {
			margin-left: 4px;
		}
		
		/deep/ .mr-4 {
			margin-right: 4px;
		}
		
	}
}
</style>
